#footer {
  //background-color: $white;

  //position: fixed;
  // bottom: 0;
  // width: 100%;
  z-index: 1;
  height: $footerHeight;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid $gray;
  .storeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px !important;

    img {
      width: auto;
      height: 100%;
      margin-right: 10px;
    }
  }

  .logo img {
    height: 30px;
  }
  #footerBottom {
    margin-top: 20px;
  }
}
