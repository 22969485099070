.mobile {
  display: none !important;
}
@media (max-width: 767.98px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  #mainWrapper {
    background: white;
    #LoginPage,
    #SignUpPage,
    #ConfirmPage,
    #ActivationPage,
    #ResendActivation,
    #RecoveryPasswordPage,
    .authPage {
      background: #fafafa;
      & > .mainRow {
        .mainCol {
        }
        .bgCol {
          flex: 0;
          display: none;
        }
      }
    }

    .mainContainer {
      max-width: 100% !important;
      // padding: 0 !important;
      padding-top: 0px !important;

      .cardContainer {
        border: none;
        background: transparent;
        margin: 0;
        .ant-card-body {
          padding: 20px;
          padding-top: 40px;
        }
        .infoWrapper {
          padding: 0;
        }
      }
      .channelImage {
        border: none;

        height: 180px;
        margin: auto;
        box-shadow: 0px 10px 20px #0000000d;
      }
      #ChannelsList,
      #BriefsList {
        padding: 0;
        padding-bottom: 20px;
        left: 0;
        right: 0;
        width: 100%;

        .ant-table-cell {
          border-bottom: 1px solid #ccc;
          border-radius: 0;

          &.textColumn {
            padding: 30px 0;
          }
        }
        .menuColumn {
          padding-right: 16px;
        }

        .bottomRow {
          margin-top: 40px;
        }
        #articleContent {
        }
        .briefAudio {
          .rhap_container {
            .rhap_progress-section {
              position: relative;
            }
            .rhap_time {
              position: absolute;
              right: 10px;
              bottom: 0;
            }
          }
        }

        .dragColumn {
          display: none;
        }
      }
    }

    .briefMenu {
      position: absolute;
      right: 16px;
      top: 20px;
    }

    .imageColumn {
      display: none;
    }
    .channelInfos {
      padding: 40px 0;
      margin: 0;
      //text-align: center;
      width: 100%;
      #channelTitle,
      #channelDescription {
        //text-align: center;
        width: 100%;
      }
      #channelTitle {
        font-size: 21px;
        font-weight: bold;
      }
      #channelDescription {
        font-size: 12px;
      }
    }

    #BusinessChannelPage {
      .BriefListElement {
        width: 100%;
        .date {
          display: none;
        }
      }
    }
  }

  #topBar {
    height: 80px;
  }
  #topBar,
  #subHeader {
    display: none !important;
  }
  #footer {
    background: transparent;
    border: none;
    flex-direction: column;
    padding: 20px 0;
    height: 120px;
    justify-content: space-evenly;

    .logo {
      margin-bottom: 20px;
    }
  }

  .ant-modal-root .ant-modal-content {
    min-width: auto !important;
    .ant-modal-body {
      padding: $padding;
    }
  }
}

@media (max-width: $maxWidth) {
  #mainWrapper {
    .mainContainer {
      max-width: 100% !important;
    }
    #subHeader {
      .subHeaderWrapper {
        max-width: 100% !important;
        //background: red;
      }
    }
  }
}
