@mixin full100 {
  width: 100%;
  height: 100%;
  flex: 1;
}

@mixin flexCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexCenter {
  @include flexCenter();
}

@mixin cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cover {
  @include cover;
}

@mixin inputMaterial {
  border-radius: 0 !important;
  border-bottom: 1px solid $grey;

  .ant-form-item-label {
    //display: none;
  }

  .ant-input-affix-wrapper {
    border: none;
    padding: 0;
    border-radius: 0 !important;
  }

  input {
    padding: 4px 11px !important;
    border: none;
    border-radius: 0 !important;
  }
}

.inputMaterial {
  @include inputMaterial();
}

@mixin blueGradient {
  background-color: $blueGradient;
}

.blueGradient {
  @include blueGradient();
}

@mixin fullAbsolute {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid red;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.shadow {
  @include shadow;
}

.smallText {
  font-size: 0.8em;
  color: $grey;
}

.ant-avatar {
  &.ant-avatar-lg {
    width: 80px;
    height: 80px;
  }

  &.xl {
    width: 120px;
    height: 120px;
  }

  @include shadow;
}

.icon-button {
  display: flex;
  align-items: center;
  min-width: 55px;
  justify-content: space-between;
  cursor: pointer;
}

.outline-button {
  display: flex;
  padding: 0 11px;
  height: 32px;
  align-items: center;
  border: 1px solid $white;
  justify-content: center;
  border-radius: $borderRadius;
  cursor: pointer;

  .anticon {
    position: relative;
    bottom: 4px;
  }
}

.ant-radio-group {
  .radioVertical {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}

.hidden {
  display: none !important;
}

@mixin transitionAll($prop: all, $time: 0.2s, $ease: ease-in-out) {
  transition: $prop $time $ease;
}

@mixin flexRow {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.flexRow {
  @include flexRow;
}

@mixin shadowBg($color: $dark) {
  // background: transparent linear-gradient(180deg, darken($color, 30%) 0%, $color 100%) 0% 0% no-repeat;
  background: transparent linear-gradient(180deg, darken($color, 30%) 0%, $color 100%) 0% 0%
    no-repeat;

  /*  background-image:
        linear-gradient(180deg, fade-out($black, .5) 50%, fade-out($color, 0) 100%),
        linear-gradient(180deg, $color 0%, $color 100%);
*/
  //   linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0));
}

@mixin flexGrow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.uppercase {
  text-transform: uppercase;
}
