$headerHeight: 100px;
$subHeaderHeight: 80px;
$footerHeight: 80px;
$maxWidth: 1040px;
$min-width: 80%;
$tableDragWidth: 40px;
$padding: 40px;
$max-widthPadded: calc(#{$maxWidth} - #{$padding});

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

@import 'colors.scss';
@import 'constants.scss';
@import 'mixins.scss';

$fontFamily: 'Raleway', 'Source Sans Pro', sans-serif !important;
body {
  margin: 0;
  font-family: $fontFamily;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white !important;

  @import 'ant-custom.scss';
  @import 'Layout.scss';
  @import 'Typography.scss';
  @import 'Buttons.scss';
  @import 'TopBar.scss';
  @import 'Footer.scss';
  @import 'Pages.scss';
  @import 'Channels.scss';
  @import 'components.scss';
  @import 'commons.scss';
  @import 'Briefs.scss';
  @import 'react-h5-audio-player.scss';
  @import 'animations.scss';
  @import 'responsive.scss';
}

html {
  @import 'ant-window.scss';
}
:focus {
  outline-color: transparent !important;
  outline-style: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-form {
  text-align: center;
}

.ant-select,
.ant-select-selector {
  cursor: pointer !important;
}
