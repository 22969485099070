//NOTIFICATIONS
.ant-notification {
  right: 0px;
  top: 0px !important;
  margin-right: 0px;

  .brief-notification {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;

    background: $lightBlue;

    .ant-notification-notice-message,
    .ant-notification-notice-description,
    .anticon {
      color: white;
    }

    &.error {
      background: $red;
    }
    &.success {
      background: $green;
    }
    &.warning {
      background: $brown;
    }
  }
}

//TOOLTIP
.ant-tooltip {
  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background-color: #fdfccc;
    }
  }
  .ant-tooltip-inner {
    border-radius: 12px;
    background-color: #fdfccc;
    color: $black;
  }
}

.commonModal {
  padding: 50px;
  text-align: center;
  .ant-modal-footer {
    border: none;
    justify-content: center;
    padding-bottom: 50px;
  }
}

.userPopover,
.TranslationPopover {
  .ant-popover-inner-content {
    padding: 0;
  }
}
