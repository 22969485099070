#CreateBriefPage {
  overflow: hidden;

  .navbar {
    cursor: pointer;
    .ant-space-item {
      display: flex;
    }
  }

  .ant-card {
    .ant-card-body {
      padding: $padding * 2;
    }

    .ant-card-actions {
      padding: $padding $padding * 2;
      li:first-child {
        text-align: left;
      }
    }
  }

  #topRow {
    align-items: flex-start;
    justify-content: space-between;
    .briefImage {
      width: 130px;
      height: 130px;
      margin-right: $padding;
    }

    #articleContent {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 130px;

      .commonTitle {
        max-width: 90%;
        margin: 0;
      }
    }
  }

  #briefContentRow {
    margin: $padding 0 $padding * 2;
    @include flexGrow;
    #briefTitle {
      height: 50px !important;
      padding: 15px 30px;
      @include TitleSmallD();
    }
    #briefText,
    .limitTextarea {
      min-height: 300px;
      @include flexGrow;
    }
    #briefTitle,
    .limitTextarea {
      width: 100%;

      border: 1px dashed #c9c9c9;
      border-radius: 12px;

      margin-top: $padding;
    }
    .limitTextarea {
      padding: 28px 30px;
    }

    .limitTextarea,
    .tweet-composer {
      @include flexGrow;
    }

    //TextArea
    .limitTextarea {
      position: relative;
      .tweet-composer {
        position: relative;
        z-index: 1;
      }

      .brief-editor,
      .brief-placeholder-back {
        background: transparent;

        outline: none;

        width: 100%;
        font-size: 18px;
        border: none;
        height: 100%;
        white-space: pre-wrap;
        word-wrap: break-word;
        padding: 0;
        resize: none;
      }

      .brief-placeholder-back {
        bottom: 0;
        color: transparent;
        left: 0;
        position: absolute;
        top: 0;

        width: 100%;

        z-index: -1;
        overflow: scroll;

        /* Hide scrollbar for IE, Edge and Firefox */
        &::-webkit-scrollbar {
          display: none;
        }

        .example {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
      }

      .brief-placeholder-back em {
        background: #fcc !important;
      }

      .charCounter {
        @include TextFieldI();
        position: absolute;
        bottom: -20px;
        right: 0;

        &.overText {
          color: red;
        }
      }
    }
  }

  #briefBottomRow {
    align-items: center;
    justify-content: space-between;

    .selectSpeaker {
      flex: 1;
      .selectSpeakerBtn {
        height: 45px;
        min-width: 180px;
        button {
          height: 45px;
          justify-content: space-between;
          align-items: center;
        }
        .currentVoice {
          img {
            height: 30px;
            margin-right: 10px;
          }
        }
      }
    }

    .audioPreview {
      margin: 0 40px;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
        filter: saturate(0%);
      }
    }
    .briefButtons {
      flex: 1;
      button {
        height: 45px;
        padding: 0 40px;
        margin: 0;
        margin-left: 20px;
      }
    }
  }
  .wizardLoading {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .ant-spin-container {
      @include flexGrow;
    }
  }
  .briefWizard {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .nav {
      margin-bottom: 15px;
      text-align: center;
    }

    .dot {
      color: black;
      cursor: pointer;
      font-size: 36px;
      line-height: 1;
      margin: 0 15px;
      opacity: 0.4;
      text-shadow: none;
      transition: opacity 1s ease, text-shadow 1s ease;
      will-change: opacity, text-shadow;
    }

    .active {
      color: var(--blue);
      opacity: 1;
      text-shadow: 0 0px 8px;
    }
    .ant-card-body {
      @include flexGrow;
      .ant-input-textarea,
      textarea.brief-editor {
        @include flexGrow;
      }
    }
    & > div {
      @include flexGrow;
      & > div {
        @include flexGrow;
        & > div {
          @include flexGrow;
        }
      }
    }
    .ant-card-body {
      flex-grow: 1;
    }
  }

  #SummaryCard {
    #SummaryTitle {
      cursor: pointer;
      .commonTitle {
        margin: 0;
      }
    }
    .subTitle {
      font-size: 16px;
    }

    textarea {
      @include BodyH();
      @include flexGrow;
    }
  }

  .sliderContainer {
    margin: 10px 0;
    .ant-slider {
      min-width: 80%;

      .ant-slider-track,
      .ant-slider-handle {
        background-color: $sliderColor;
        border-color: $sliderColor;
      }
      .ant-slider-handle,
      .ant-slider-dot-active {
        border-color: $sliderColor;
      }
    }
    .charsCounter {
      margin-left: $padding;
      // margin-bottom: 18px;
    }
  }
}

.BriefListElement {
  height: 100%;
  flex: 1;
  min-height: 115px;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  display: flex;
  width: 75%;
}

.date {
  text-transform: uppercase;
}

.briefAudio {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.briefActions {
  margin-left: 20px;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  //min-width: 70px;
  .SwitchStatus,
  .openSharer {
    margin-left: 20px;
  }
}

.customPlay {
  border: none;
  .anticon {
    display: flex;
  }
}
.languageBrief {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px;
  width: 80px;
  height: 80px;
  opacity: 0.4;
  position: relative;

  &.current {
    border: 1px solid;
    border-radius: 20px;
    opacity: 1;
  }

  &.addNewLanguage {
    opacity: 1;
  }

  .deleteTranslationIcon {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -14px;
    right: -14px;
    background: white;
    font-size: 1.2em;
    background: transparent;
    @include transitionAll;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .deleteIcon {
      background: white;
      &:hover {
        color: red;
      }
    }
  }

  &.current:hover {
    .deleteTranslationIcon {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.TranslationPopover {
  .ant-popover-title {
    text-align: center;
  }
  .ant-tabs-tabpane {
    min-height: 300px;
  }
}
.TranslationPopoverContent {
  min-width: 250px;
  padding: 12px 16px;
  min-height: 400px;
  margin: auto;
  .ant-tabs-nav {
    display: none;
  }

  .tabSpeaker {
    text-align: center;

    .voiceCard {
      //min-width: 240px;
      min-height: 220px;
      text-align: center;
      width: 100%;
      align-items: center;
      margin: 0 auto 20px;
    }
    .slick-dots {
      button {
        background: $main !important;
      }
      bottom: -10px;
    }

    .tabMp3 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      min-height: 220px;
      margin: 0 auto 20px;
    }
  }

  .TranslationPopoverActions {
    width: 80%;
    margin: 20px auto;
    .buttonContainer {
      position: relative;
    }
    button {
      max-width: 150px;
    }
  }

  // .audioPlayer.simple {
  //   filter: saturate(0%);
  //   opacity: 0.2;
  //   &:hover {
  //     filter: none;
  //     opacity: 1;
  //   }
  // }

  .bgAudioHeader {
    display: flex;
    margin: 20px 0;
    align-items: center;
    font-weight: bold;
    .anticon {
      margin-right: 10px;
    }
  }
  .bgAudioList {
    .bgAudioRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;

      &.selected {
        color: $main;
      }
      .bgTitle {
        text-align: left;
        flex: 2;
        margin-left: 10px;
      }
    }
  }

  .clearBgAudio {
    position: absolute;
    top: -10px;
    right: 0px;

    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    border: none;
    .anticon {
      width: 30px;
      height: 30px;
      font-size: 1em;
      background: $icon;
      color: #fffcfc;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;

      &:hover {
        cursor: pointer;
        color: #fffcfc;
      }
      &:active {
        display: none;
      }
    }
  }
}

#articleLink {
  #articleUrl {
    margin: 0 10px;
  }

  #articleFavIcon {
    border-radius: 50%;
  }

  &:hover {
    #articleUrl {
      text-decoration: underline;
    }
  }
}
