#mainWrapper {
  #LoginPage,
  #SignUpPage,
  #ConfirmPage,
  #ActivationPage,
  #ResendActivation,
  #RecoveryPasswordPage,
  .authPage {
    padding: 0;

    .commonTitle {
      font-weight: 300;
    }
    a:link,
    a:visited,
    a:active {
      color: #333;
      text-decoration: underline;
    }

    & > .mainRow {
      width: 100%;
      height: 100%;

      .mainCol {
        width: 100%;
        height: 100%;
        flex: 1;
        @include flexCenter;
        align-items: center;
        flex-direction: row;
        background-color: $lightAzure;
      }
    }

    .formBox {
      //min-width: 402px;
      padding: 10px;
      width: 90%;
      margin: auto;
      max-width: 400px;
      min-height: 400px;

      label {
        display: none;
      }
      .checkbox label {
        display: inline;
      }
      .ant-checkbox-wrapper {
        display: flex;
      }
      input,
      .ant-input-suffix {
        background: $lightAzure;
      }
    }

    form {
      width: 100%;
    }

    .ant-col.bgCol {
      @include cover;
      background-position: center;
      background-image: url('/background.jpg');
      background-color: $lightAzure;

      &.bgLogin {
        background-image: url('/background-login.jpg');
      }

      //background-image: url(../assets/images/background.jpg);
    }

    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;

    .logo {
      margin-bottom: 40px;

      img {
        margin: auto;
        width: auto;
        height: 58px;
      }
    }

    form {
      @include flexCenter;
      align-items: center;
    }

    #footer {
      display: none;
    }

    [type='checkbox'][class*='filled-in']:checked + label:after {
      border-color: #ff8800;
      background-color: #ff8800;
    }
  }

  #ActivationPage {
    background: $white;
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
  .ant-page-header-heading {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    .ant-page-header-heading-title {
      font-size: 2em;
    }
  }

  #BriefPage {
    #briefEditor {
      background-color: white;

      & > .ant-col {
        padding: 20px;
        width: 100%;
      }

      .horizontal-input {
        flex-direction: row;
        justify-content: start;
        align-items: center;

        .ant-form-item-label {
          margin-right: 20px;
          padding: 0;
        }

        .ant-form-item-control {
          width: auto;
          text-align: left;
        }
      }

      .ant-form-item-label {
        // display: none;
        font-weight: Bold;
        letter-spacing: 0.6px;
      }
    }

    .UploadImage {
      min-height: 250px;
      @include shadow;
    }
  }

  #page404 {
    max-width: 100%;
    background-color: white;
    .bg404 {
      @include cover;
      background-image: url('/404.jpg');
      background-color: white;
      background-size: contain;

      height: 100%;
      position: absolute;
      right: -30%;
      bottom: 0;
      width: 100%;
    }

    .text404 {
      position: absolute;
      left: 10%;
      top: 30%;

      h2 {
        font-size: 5em;
        margin: 0;
      }
      h3 {
        font-size: 3em;
        line-height: 100%;
      }
    }
  }

  #BusinessChannelSettingsPage {
    .coeditorsForm {
      max-width: 300px;
    }

    .coeditorsList {
      & > .ant-space-item {
        padding: 5px 10px;
        border-radius: $borderRadius;
        &:nth-child(even) {
          background-color: rgb(230, 230, 230);
        }
      }
      .coeditorItem {
        min-width: 300px;
      }
    }
  }
}

.speakerPopover {
  .ant-popover-title {
    display: none;
  }
  .ant-popover-inner-content {
    padding: $padding;
  }
  .speakerFlag {
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }

    &.current {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .ant-space {
    justify-content: space-around;
    text-align: center;
  }
  .speakerLang {
    text-transform: uppercase;
  }

  .voiceAvatar {
    opacity: 0.5;
    cursor: pointer;
    text-align: center;

    &.selected,
    &:hover {
      opacity: 1;
    }
  }
}
