//Responsive breakpoints
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
//// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;
//// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;
//// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;
//// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;
//// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

.antd-img-crop-modal {
  z-index: 9999;

  .ant-slider-handle {
    background: $main;
    border: $main;
  }
}

.ant-modal-root {
  .ant-modal-content,
  .ant-modal-header,
  .ant-card {
    border-radius: 17px !important;
  }

  .ant-modal-header {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    //border: none !important;
    padding: 20px;
    button {
      min-width: 120px;
    }
    button + button {
      margin-bottom: 0;
      margin-left: 30px;
    }
  }

  .ant-modal-wrap {
    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-modal-content {
    min-width: 500px;
  }
  .ant-modal-body {
    padding: 40px 60px;
    text-align: center;
  }
  //CONFIRM MODAL
  .ant-modal-confirm-body {
    .anticon-exclamation-circle {
      display: none;
    }
    .ant-modal-confirm-title {
      color: $dark;
      margin: auto;
      font-size: 1.5em;
    }
    .ant-modal-confirm-content {
      margin: 10px auto !important;
      text-align: center !important;
    }
  }
  .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $gray;
    padding-top: 20px;

    align-items: center;
    justify-content: center;

    button:first-child {
      margin-right: 30px;
    }
  }

  .welcomeModal {
    .logo img {
      height: 80px;
    }
    .ant-modal-content {
      &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        background-image: url('/Logo.svg');
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 110%;
      }
    }
    .ant-modal-content {
      min-width: 600px;
      padding: 40px;
    }
  }
}

.ant-table {
  border: none;

  table,
  .ant-table-tbody,
  .ant-table-cell {
    border: none;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 15px;
  }
}

//UPLOAD

.reactEasyCrop_CropArea {
  border: 2px solid rgba(255, 255, 255, 0.9) !important;
}

.ant-dropdown-menu {
  min-width: 140px;
  border-radius: 8px;
  .ant-dropdown-menu-item {
    padding: 0px 6px;
    margin: 0;
    button {
      margin: 0;
      width: 100%;
      text-align: left;

      border: none;
      box-shadow: none;
      background: transparent;
      &:hover {
        box-shadow: none;
      }
    }
    span {
      text-transform: uppercase;
      font-weight: bold;
      color: $dark;
      font-size: 0.9em;
    }
  }
}

.antd-img-crop-container {
  border-radius: 10px;
}

.UploadImage {
  // border: 2px solid $main;
  //border-radius: $borderRadius;
  @include cover;
  position: relative;
  min-width: 80px;
  min-height: 80px;

  .uploadDrag {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &.circle {
    border-radius: 50%;
  }

  &.noImage {
    //  border: 2px dotted transparent;
  }

  .ant-upload-drag-container {
    transition: opacity 0.2s ease-in-out;
  }

  &.hasImage.showImage {
    .ant-upload-drag-container {
      position: absolute;
      bottom: 0px;
      right: 0px;
      transform: scale(0.6);
    }

    .ant-upload.ant-upload-drag {
      border: none;
    }
  }

  &:hover,
  .ant-upload-drag-hover {
    .ant-upload-drag-container {
      opacity: 1 !important;
    }

    .anticon {
      font-size: 1.3em !important;
    }
  }

  .ant-upload {
    min-width: 80px;
    min-height: 80px;
  }

  .ant-upload.ant-upload-drag {
    background: transparent;

    .ant-upload-drag-icon {
      background: white;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 0.8;
      font-size: 1.8em;
      margin: auto;
      @include shadow;
      .anticon {
        font-size: 1em;
        transition: all 0.2s ease-in-out;
        color: $dark;
      }

      .uploadMessage {
        font-size: 10px;
        color: white;
      }
    }
  }

  /* &.full {
@include fullAbsolute;
.uploadDrag {
@include fullAbsolute;
position: relative;
.ant-upload-select {
@include fullAbsolute;
position: relative;
align-items: center;
.ant-upload {
@include fullAbsolute;
display: flex;
position: relative;
align-items: center;
justify-content: flex-start;
.dragZone {
width: 200px;
position: absolute;
}
}
}
}
}*/
}

//FORMS

.ant-form {
  .ant-form-item.input {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .ant-form-item-control,
  input,
  select {
    width: 100%;
  }
}
.ant-input[disabled] {
  background: transparent;
}

.ant-input.editableInput {
  border: none;
  background-color: transparent;
  color: white;
}

.ant-row {
  width: 100%;
}

//NOTIFICATIONS
.ant-notification {
  right: 0px;
  top: 0px !important;
  margin-right: 0px;

  .brief-notification {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;

    background: #f5f5f5;

    .ant-notification-notice-message,
    .ant-notification-notice-description,
    .anticon {
      color: $dark;
    }

    // &.error {
    //   background: $red;
    // }
    // &.success {
    //   background: $green;
    // }
    // &.warning {
    //   background: $brown;
    // }
  }
}

//TOOLTIP
.ant-tooltip {
  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background-color: #fdfccc;
    }
  }
  .ant-tooltip-inner {
    border-radius: 12px;
    background-color: #fdfccc;
    color: $black;
  }
}

.ant-card {
  border-radius: $borderRadius;

  .ant-card-actions {
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;

    padding: $padding;
  }
}

.ant-input {
  border-radius: 10px;
}

.ant-avatar-square {
  border-radius: $borderRadius;
}

.userPopover {
  //  padding: 0;
}
.ant-popover-arrow {
  display: none;
}

.editable {
  border: 1px solid transparent;

  &:hover:not(.disabled) {
    border: 1px dashed #ccc;
  }
}

.carouselWrapper {
  position: relative;

  .ant-carousel .slick-dots li {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    overflow: hidden;
    button {
      width: 8px;
      height: 8px;
      background: #4b4b4b !important;
    }

    &.slick-active {
      button {
        background: $main !important;
      }
    }
  }
  .carouselPrev,
  .carouselNext {
    position: absolute;
    font-size: 1em;
    top: 120px;

    &:hover {
      opacity: 0.5;
    }
  }
  .carouselPrev {
    left: 0;
  }
  .carouselNext {
    transform: rotateY(180deg);
    right: 0;
  }
}
