//@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

@mixin Regular() {
  font-weight: 400;
}
@mixin Medium() {
  font-weight: 500;
}
@mixin SemiBold() {
  font-weight: 600;
}
@mixin Bold() {
  font-weight: 700;
}

.ant-typography {
  color: $textColor;
  font-family: $fontFamily;
  @include Bold();
}
h1,
h2,
h3,
h4,
h5 {
  &.ant-typography {
    color: $textColor;
  }
}
//Title Big A
h1.ant-typography {
  font-size: 46px;
  line-height: 54px;
  @include Bold();
}

@mixin TitleMediumB {
  font-size: 30px;
  line-height: 35px;
  @include Bold();
}
//Title Medium B
h2.ant-typography {
  @include TitleMediumB();
}

//Title Section C
h3.ant-typography {
  font-size: 24px;
  line-height: 29px;
  @include Bold();
}

@mixin TitleSmallD {
  font-size: 18px;
  line-height: 21px;
  @include Bold();
}
//Title small D
h4.ant-typography {
  @include TitleSmallD();
}

.subTitle {
  //SubTigle BIG
  @include SemiBold();
  font-size: 20px;
  line-height: 24px;

  &.section {
    @include Regular();
    font-size: 16px;
    line-height: 19px;
  }

  &.body {
    @include Medium();
    font-size: 16px;
    line-height: 19px;
  }
}

@mixin BodyH() {
  @include Regular();
  font-size: 18px;
  line-height: 21px;
}
.body {
  @include BodyH();
}

@mixin TextFieldI() {
  @include Medium();
  font-size: 14px;
  line-height: 16px;
}
.textField {
  @include TextFieldI();
}

@mixin TextFieldPlaceholderJ() {
  color: $textColor;
  opacity: 0.7;
  font-size: 14px;
  line-height: 16px;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  @include TextFieldPlaceholderJ();
}

.link {
  @include Regular();
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
}

@mixin TopBarText() {
  @include SemiBold();
  font-size: 18px;
  line-height: 21px;
  color: $textColor;
}
.navbar {
  @include TopBarText();
}
