$rhap_theme-color: $main !default;
$rhap_background-color: #fff !default;
$rhap_bar-color: $red !default;
$rhap_time-color: #262626 !default;
$rhap_font-family: inherit !default;
$rhap_theme-active-color: $main !default;
$borderRadius: 24px;
$minWidthAudio: 350px;

//
:root {
  --minWidthAudio: 350px;
}

.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: $rhap_font-family;
  width: 100%;

  //padding: 10px 15px;
  // background-color: $rhap_background-color;
  //box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  &:focus:not(:focus-visible) {
    outline: 0;
  }

  .rhap_header {
    margin-bottom: 10px;
  }

  .rhap_footer {
    margin-top: 5px;
  }

  .rhap_main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .rhap_stacked {
    .rhap_controls-section {
      margin-top: 8px;
    }
  }

  .rhap_horizontal {
    flex-direction: row;

    .rhap_controls-section {
      margin-left: 8px;
    }
  }

  .rhap_horizontal-reverse {
    flex-direction: row-reverse;

    .rhap_controls-section {
      margin-right: 8px;
    }
  }

  .rhap_stacked-reverse {
    flex-direction: column-reverse;

    .rhap_controls-section {
      margin-bottom: 8px;
    }
  }

  .rhap_progress-section {
    display: flex;
    flex: 3 1 auto;
    align-items: center;
  }

  .rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 calc(10px + 1%);
    cursor: pointer;
    -webkit-user-select: none;

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .rhap_time {
    color: $rhap_time-color;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
  }

  .rhap_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 3px;
    background-color: $grey;
    border-radius: $borderRadius;
  }

  .rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: $rhap_theme-color;
    border-radius: $borderRadius;
  }

  .rhap_progress-bar-show-download {
    background-color: rgba($rhap_bar-color, 0.5);
  }

  .rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: $grey;
    border-radius: $borderRadius;
  }

  .rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    top: -8px;
    background: $rhap_theme-color;
    border-radius: 50px;
    box-shadow: rgba($rhap_theme-color, 0.5) 0 0 5px;
  }

  .rhap_controls-section {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
  }

  .rhap_additional-controls {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
  }

  .rhap_repeat-button {
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: $rhap_theme-color;
    margin-right: 6px;
  }

  .rhap_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      box-shadow: none !important;
    }
  }

  .rhap_main-controls-button {
    margin: auto;
    color: $rhap_theme-color;
    font-size: 35px;
    width: 35px;
    height: 35px;
  }

  .rhap_play-pause-button {
    font-size: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg path {
      fill: $main;
    }
  }

  .rhap_volume-controls {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
  }

  .rhap_volume-button {
    flex: 0 0 26px;
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: $rhap_theme-color;
    margin-right: 6px;
  }

  .rhap_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    -webkit-user-select: none;
  }

  .rhap_volume-bar-area {
    display: flex;
    align-items: center;
    width: 100%;
    height: 14px;
    cursor: pointer;

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .rhap_volume-bar {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 4px;
    background: $rhap_bar-color;
    border-radius: $borderRadius;
  }

  .rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    left: 0;
    top: -4px;
    background: $rhap_theme-color;
    opacity: 0.9;
    border-radius: 50px;
    box-shadow: rgba($rhap_theme-color, 0.5) 0 0 3px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  /* Utils */
  .rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
      transition-duration: 0.2s;
    }

    &:active {
      opacity: 0.95;
    }

    &:focus {
      outline: 0;
    }
  }
}

.audioPlayer {
  transition: all 0.2s ease-in-out;

  min-width: 350px;

  &.paused {
    .rhap_play-pause-button {
      color: $black;
    }

    .rhap_play-pause-button {
      svg path {
        fill: $gray;
      }
    }

    .rhap_progress-filled {
      background-color: $gray;
    }

    .rhap_progress-bar-show-download {
      background-color: rgba($gray, 0.5);
    }

    .rhap_progress-indicator {
      background: $gray;

      box-shadow: rgba($gray, 0.5) 0 0 5px;
    }
  }

  &.playing,
  &:hover {
    .rhap_play-pause-button {
      svg path {
        fill: $rhap_theme-active-color;
      }
    }

    .rhap_progress-filled {
      background-color: $rhap_theme-active-color;
    }

    .rhap_progress-bar-show-download {
      background-color: rgba($rhap_theme-active-color, 0.5);
    }

    .rhap_progress-indicator {
      background: $rhap_theme-active-color;

      box-shadow: rgba($rhap_theme-active-color, 0.5) 0 0 5px;
    }
  }
}

.rhap_controls-section {
  display: none;
}

// .rhap_total-time,
// .ListenNow {
//     display: none;
// }

.rhap_current-time {
  display: block;
}

.audioPlayer {
  min-width: var(--minWidthAudio);
}

.anticon {
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}

.audioPlayer {
  &.simple {
    width: auto;
  }
}

.rhap_controls-section {
  margin: 0 !important;
}
