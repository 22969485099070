@mixin btnShadow {
  box-shadow: 0px 3px 6px #2967e633;
}

.btnShadow {
  @include btnShadow;
}

.ant-btn {
  padding: 4px 30px;

  background: transparent;
  @include SemiBold();
  font-size: 14px;
  font: normal normal 600 14px/14px Raleway;
  color: $darkGray;
  letter-spacing: 0px;
  border-radius: 22px;

  border: 1px solid $darkGray;

  span {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover,
  &:active,
  &:focus {
    @include btnShadow;
    color: $darkGray;
    border-color: $darkGray;
  }
  &.ant-btn-primary {
    border: none;
    color: white;
    background: linear-gradient(180deg, $main 100%, #d50c3a 100%);
    @include btnShadow;
    &[disabled] {
      background: #fd1e0b80;
    }
    &:hover {
      background: linear-gradient(180deg, $main 0%, #d50c3a 100%);
    }
    &:active {
      background: transparent linear-gradient(180deg, #d30f2d 0%, #e72025 56%, #d50c3a 100%) 0% 0%
        no-repeat padding-box;
    }
  }

  &.white {
    border-color: white !important;
    color: white;
    box-shadow: none;
    &:hover {
      background: white;
      color: $main;
    }
  }
}

a {
  border: none;
  box-shadow: none;
  background: transparent;
  &:hover {
    box-shadow: none;
  }
}

.ant-btn-icon-only {
  display: flex;
  align-items: center;
  justify-content: center;
}

/***

ICONE
*******/
.anticon.anticon-exclamation-circle {
  color: $red !important;
}
.anticon,
.briefIcon {
  color: $icon;
  background: transparent;
  transition: all 0.2s ease-in-out;

  &.addIconFill,
  &.primary {
    border-radius: 50%;
    font-size: 2em;
    background: $main;
    color: white;
    padding: 10px;

    &:hover {
      color: white;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    color: $darkGray;

    -webkit-filter: drop-shadow(0px 2px 5px #39496233);
    filter: drop-shadow(0px 2px 5px #39496233);
  }

  &:active {
    color: $iconActive;
  }
}

.sharerGray {
  font-size: 1em;

  text-align: center;
  margin-left: 20px;
}
