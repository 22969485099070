.fullWidth {
  width: 100%;
}

.textRight {
  text-align: right;
}

.halfpadded {
  padding: $padding/2;
  @include flexCenter;
}

.padded {
  padding: $padding;
}

.doublePadded {
  padding: $padding * 2;

  @include flexCenter;
}

.avatar {
  background-color: $white;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  @include shadow;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.noItemsFound {
  width: 100%;
  overflow: hidden;
  padding: 0 40px;
  .skeleton {
    .ant-skeleton-avatar {
      margin: 20px;
      width: 100px;
      height: 100px;
      margin-left: 0;
    }
    display: flex;
  }
}

.limit2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.pushRight {
  float: right;
}

.squareAvatar {
  min-width: 120px;
  min-height: 120px;
  background-color: $white;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  @include shadow;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexSpace {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.spacer {
  width: 100%;
  display: block;
  height: 1px;
}

.flexRow {
  flex-direction: row;
}
.flexCol {
  flex-direction: column;
}
.flexCenter {
  align-items: center;
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.responsiveImg {
  width: 100%;

  height: 50%;
  margin: auto;
  background-image: url('/activation.jpg');
  @include cover;
}

.noPadding {
  padding: 0px !important;
}

.noMargin {
  margin: 0px !important;
}

.invisible {
  visibility: hidden;
  height: 1px;
  overflow: hidden;
}

textarea {
  resize: none;
  background-color: transparent !important;
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: inherit !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0 1000px #fafafa inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.reactour__helper {
  border-radius: 6px;
  padding: 40px 30px 20px;
}

.isDragging {
  // cursor: move;
  // transform: rotate(356deg);
  // background: white;
  // border-radius: 20px;
  // border: 1px solid gray;
}
.drop-over-upward {
  .ant-table-cell {
    border-top: 5px solid $main;
  }
}

.drop-over-downward {
  .ant-table-cell {
    border-bottom: 5px solid $main;
  }
}

.textLeft {
  text-align: left;
}
