#mainWrapper {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;

  min-height: 100vh;

  &.hasTopbar {
    .mainContainer {
      flex-grow: 1;
      padding-top: $headerHeight + $padding * 2;
      //min-height: 100vh;
      padding-bottom: 40px; // $footerHeight;
      // padding-left: 50px;
      // padding-right: 50px;
      max-width: $maxWidth;
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      //max-width: 90%;
    }
  }
}
