#mainWrapper {
  #topBar {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease-in-out;
    height: $headerHeight;
    z-index: 2;
    background-color: $main;
    //    @include shadowBg($green);
    padding: 0 5%;
    #logo {
      cursor: pointer;
      width: 110px;
      margin-bottom: 10px;
    }

    .headerContent {
      //max-width: $maxWidth;
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-menu {
        background: transparent;
      }

      #leftHeader {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      #breadcrumbs {
        .ant-breadcrumb,
        a {
          @include TopBarText();
        }
        margin-left: 60px;
        //padding-top: 5px;
        .ant-breadcrumb-link,
        .ant-breadcrumb-separator,
        a,
        span {
          color: white;
        }
        .ant-breadcrumb-separator {
          pointer-events: none;
        }
        .current {
          pointer-events: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      #rightHeader {
        // .channelsCount {
        //   margin-right: 40px;
        //   background-color: $dark;
        //   color: white;
        //   border-color: $dark;
        // }
        color: white;
        .userAvatar {
          color: white;
        }
      }
    }
  }
  #subHeader {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: $headerHeight;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease-in-out;
    height: $subHeaderHeight;
    z-index: 2;
    background-color: $white;
    border-bottom: 1px solid $gray;
    .subHeaderWrapper {
      margin: 0 auto;
      width: 100%;
      max-width: $maxWidth;

      .subHeaderContent {
        position: relative;
        padding: 0 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0 auto;
        width: 100%;

        .channelRoutes {
          flex: 1;
          text-align: center;
          @include TopBarText();
          .linkPost,
          .linkAnalitics,
          .linkSettings {
            cursor: pointer;

            &:hover {
              div {
                color: $main !important;
              }
            }
          }
          &.post {
            .linkPost {
              pointer-events: none;
              div {
                color: $main !important;
              }
            }
          }
          &.analitics {
            .linkAnalitics {
              pointer-events: none;
              div {
                color: $main !important;
              }
            }
          }
          &.settings {
            .linkSettings {
              pointer-events: none;
              div {
                color: $main !important;
              }
            }
          }
        }
        .channelSubheaderActions {
          position: absolute;
          right: 5px;

          .openSharer {
            margin-left: $padding;
          }
          .ant-space-item {
            display: flex;
          }
        }

        .ant-typography {
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
.UserPopover {
  cursor: pointer;
}
#userPopoverContent {
  min-width: 340px;
  text-align: center;

  .ant-tabs-nav {
    display: none;
  }

  .userForm {
    width: 90%;
    margin: auto;
  }
  .anticon-down {
    color: white;
  }
  .useAvatar {
    background: $main !important;
    color: white;
    margin: $padding 0 10px;
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .userAvatarUpload {
    width: 100px;
    height: 100px;
    margin: auto;

    .UploadImage {
      width: 100px;
      height: 100px;
    }

    .ant-upload-drag-container {
      bottom: -10px;
      right: -10px;
    }
  }

  label {
    display: none;
  }
}

.useAvatar {
  background: white !important;
  color: $main;
  span {
    font-size: 12px;
  }
}

.userFullName {
  @include SemiBold();
  font-size: 18px;
  text-transform: capitalize;
  margin-left: 10px;
}
