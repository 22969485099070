.sharerModal {
  .react-share__ShareButton {
    display: flex;
  }

  .ant-modal-footer {
    display: none;
  }

  .logo {
    width: 100px;
    height: auto;
    margin: 20px auto;
  }
  .shareRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;

    .commonTitle {
      margin-bottom: 10px;
    }
  }

  .copyShare {
    background: #ebebeb 0% 0% no-repeat padding-box;
    border-radius: 35px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    .shareLink {
      width: 80%;
    }
    .anticon-copy {
      opacity: 0;
    }
  }
  .shareIcons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .boxIcon {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-right: 10px;
    }
  }
}

.ant-table {
  .ant-table-row {
    .hoverRow {
      width: auto;
      @include transitionAll;
      opacity: 0;
      img {
        //width: 60%;
        height: auto;
      }
    }

    &:hover {
      .hoverRow {
        opacity: 1;
      }
    }
  }
}

.briefColumn {
  // background-color: $main;
  .ant-form-item-label label {
    color: white;
  }
}

.bubble {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 5%;

  .bubbleText {
    position: absolute;
    top: 24%;
    font-size: 2em;
    width: 60%;
    height: 100px;
    font-weight: bold;
    font-family: FilsonSoftMedium, Comic sans, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height: 100%;
  }
}

.privacyLink {
  position: fixed;
  bottom: 20px;
  font-size: 10px;
  color: black;
}

.hashTagsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //margin-left: 10px;
  width: 100%;
  color: $white;

  .briefTag {
    background: transparent;
    border-radius: 18px;
    border: none;
    //color: $white;
    margin-right: 8px;
    color: $white;

    span {
      //color: $grey;
      position: relative;
      bottom: 3px;
    }
  }

  .ant-select {
    flex: 1;
    border: 1px solid $grey;
    border-radius: $borderRadius;

    .ant-select-selector {
      //background: transparent;
      border: none;

      .ant-select-selection-search {
        color: $white;
      }
    }
  }

  &.trasparent {
    .ant-select {
      border: none;

      .ant-select-selector {
        background: transparent;

        .briefTag {
          background: transparent;
          border: 1px solid $white;
          color: $white;
        }

        .ant-select-selection-search {
          color: $white;
        }
      }
    }
  }
}

.roundedSelect {
  .ant-select-selector {
    color: white !important;
    background: transparent !important;
    border-radius: $borderRadius !important;
  }

  .ant-select-arrow {
    color: white !important;
  }
}

#channelStatusSelect {
  position: absolute;
  right: 30px;
  top: 30px;
}

.actionsMenu {
  font-size: 2em;
  color: $textColor;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 3em;
  button span {
    color: $textColor;
  }
}

.logo {
  img {
    text-align: center;
    margin: auto;
    width: auto;
    height: 40px;
  }
}

.bgCover {
  background-color: white;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  // width: 50vw;
  // height: 50vw;
  position: relative;
  border-radius: $borderRadius;
  height: 0;
  width: 100%;
}

.statuIndicator {
  .ant-badge-status-text {
    color: black;
  }
}

.briefSize .ant-form-item-control-input-content {
  padding: 0 15px;
}

.SocialLogin {
  margin-top: 20px;

  .roundedIcon {
    @include shadow;
  }
}

.roundedIcon {
  width: 40px;
  height: 40px;
  @include flexCenter;
  @include cover;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

#debugMenu {
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: row;
  top: 0;
  align-items: flex-start;
  justify-content: space-around;
  z-index: 9;
  margin: 10px auto;

  li {
    margin: 0 10px;
  }
}

.addBriefRow {
  height: 130px;
  display: flex;
  background: #f73a0b08;
  padding: 0 80px;
  position: relative;
  left: -40px;
  right: 10px;
  width: calc(100% + 80px);

  .ant-input-affix-wrapper {
    flex: 3;
    height: 36px;
    border: 1px solid #dbdbdb;
    border-radius: 25px;
  }

  .createBrief {
    margin: 0 10px 0 20px;
  }
}
