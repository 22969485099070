#TableListWrapper {
  #ChannelsList {
    background: transparent;
  }
  .menuColumn {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .ownChannel {
    .ant-table {
      left: -$tableDragWidth;
      width: calc(100% + #{$tableDragWidth});
      padding: 0 $padding * 2;

      tr:hover {
        cursor: pointer !important;
      }
    }

    .dragColumn {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      cursor: move !important;
    }
    .imageColumn {
      padding-left: 0;
    }
  }

  .publicChannel {
    .imageColumn {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    // .textColumn {
    //   border-top-right-radius: 20px;
    //   border-bottom-right-radius: 20px;
    // }

    .ant-table {
      left: -15px;
      width: calc(100% + 10px);

      padding: 0 $padding * 2;
    }
  }

  .dragColumn {
    //border: 1px solid;
    width: $tableDragWidth;
    padding: 0;
    text-align: center;
    img {
      display: none;
    }
  }

  .imageColumn {
    width: 115px;
    .ant-avatar {
      width: 115px;
      height: 115px;
    }
  }
  .textColumn {
    vertical-align: baseline;
    .ChannelDescription {
      & > div {
        margin-bottom: 10px;
      }

      .SwitchStatus {
        margin-right: 20px;
      }
    }
  }
  .menuColumn {
    width: 40px;
    vertical-align: baseline;
    padding: 0px;
    padding-right: 0px;
    padding-top: 2px;
    font-size: 1em;
    text-align: right;
  }
}
.cardContainer {
  border-radius: 20px;
  //max-width: $max-widthPadded;
  width: 100%;
  margin: 60px auto;

  padding: 0px;
  border: $border;

  .channelImage {
    border: $border;
  }

  .ant-card-body {
    padding: $tableDragWidth;
  }

  .infoWrapper {
    padding: 0 $padding * 2;
    //padding: 0px;
  }
  .channelInfos {
    margin: 40px 0 20px 0;
    width: 100%;

    .channelAdd {
    }
  }
  .channelImage {
    background-color: white;
    border-radius: 10px;
    position: relative;
    width: 100%;
    padding-top: 30%; /* 8:5 Aspect Ratio */
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  #channelTitle {
    @include TitleMediumB();
    width: 50%;
    //min-height: 30px;
    display: block;
  }

  #channelDescription,
  .channelDescription {
    @include TextFieldPlaceholderJ();
    margin-top: 10px;
    width: 80%;
    //min-height: 50px;
    display: block;
  }

  .ant-table-thead {
    display: none;
  }
  .rowAvatarImg .ant-avatar {
    width: 100px;
    height: 100px;
  }

  .RuleTag {
    position: absolute;
    right: 0;
    top: 60px;
    margin: 0;
    border-radius: 6px;
    font-weight: bold;
  }
}

.SwitchStatus {
  .SwitchLabel {
    margin-right: 10px;
  }
  .SwitchToggle {
  }
}

.channelTags {
  margin-top: 10px;

  .channelTagsSelect {
    width: 80%;
    .ant-select-selector,
    .ant-select-focused {
      border: none !important;
      box-shadow: none !important;
    }

    .ant-select-selection-item-content,
    .ant-select-item-option-content {
      &::before {
        content: '#';
      }
    }
  }

  .tags span {
    color: #414141;
    opacity: 0.7;

    font-size: 14px;

    &::before {
      content: '#';
    }
  }
}
