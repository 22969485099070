$main: #fd3a0b;
$black: #414141;
$darkGray: #646672;
$lightGray: #9fa5af;
$icon: #9fa5af;

$iconHover: $darkGray;
$textColor: $black;
$iconActive: #505469;

///OLD
$blue: #4d596c;
$grey: #c2c2c2;
$gray: $grey;
$dark: #55546c;
$lightAzure: #fafafa;
$textLight: #4d4f5c;
$textDark: $dark;
$white: #fff;
$border: 0.1rem solid #dbdbdb;
$label: #8c97a6;
$black: #383838;
$blueGradientAlpha: $blue; //linear-gradient(180deg, fade-out($black, .2) 50%, fade-out($blue, .2) 100%);
$blueGradientOnly: $blue; //linear-gradient(180deg, $black 0%, $blue 100%);
$blueGradient: $blue; //transparent $blueGradientOnly 0% 0% no-repeat padding-box;
$borderRadius: 10px;
$sliderColor: $main; //#424e62;
//BGS
$red: #d03939;

$orange: #f0550f;

$lightBlue: #39a7d0;

$violet: #8f56f1;

$green: #39d045;

$lightGreen: #39d0ad;

$purple: #cb4bd6;

$yellow: #d0ca39;

$strongGreen: #85d039;

$dark: #6c6c6c;

$bluette: #4f60e3;

$brown: #d09c39;
