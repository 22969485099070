.btnAnimated {
  padding: 0.85em 0.75em;
  margin: 1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:hover {
  }
  &::after {
    content: '';
    background: #ecf0f1;
    position: absolute;
    z-index: -1;
    padding: 0.85em 0.75em;
    display: block;
  }
  &[class^='slide']::after {
    transition: all 0.35s;
  }
  &[class^='slide']:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.35s;
  }
  &.slide_from_left::after {
    top: 0;
    bottom: 0;
    left: -100%;
    right: 100%;
  }
  &.slide_from_right::after {
    top: 0;
    bottom: 0;
    left: 100%;
    right: -100%;
  }
  &.slide_from_top::after {
    left: 0;
    right: 0;
    top: -100%;
    bottom: 100%;
  }
  &.slide_from_bottom::after {
    left: 0;
    right: 0;
    top: 100%;
    bottom: -100%;
  }
}
